import * as React from "react";
import PageComponent from "src/components/pages/learn/why-buy-us-life-insurance";
import graph1 from "src/images/graph-1.jpg";
import graph2 from "src/images/graph-2.jpg";
import graph3 from "src/images/graph-3.jpg";

const Page = ({ location }) => {
  const copy = {
    list: [
      <div>
        <span>
          Life insurance sold in Israel today is far inferior to that which is
          sold in the USA in terms of both price and product quality. Whereas a
          US resident can enjoy risk/term life insurance at very affordable
          prices with contracts that guarantee no increase in premium for
          10/15/20/25 and even 30 years, similarly healthy residents of Israel
          pay an initial premium as much as 3 to 4 times as high and the cost
          goes up each policy year.
        </span>
        <div className="mx-auto lg:flex lg:flex-row lg:flex-wrap -mx-20 my-2 text-center justify-center">
          {[graph1, graph2, graph3].map((item, key) => (
            <a
              key={key}
              href={item}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full lg:w-6/12 my-4 lg:my-0 box-border p-5"
            >
              <img src={item} alt="Graph #1" />
            </a>
          ))}
        </div>
        <span>
          US residents also have the ability to purchase lifetime guaranteed
          level premium life insurance coverage for permanent liquidity needs.
          No such policy even exists in Israel today.
        </span>
      </div>,
      `The problem is that virtually no US carrier will underwrite or issue a
      life policy on a resident of Israel.`,
      `We represent 2 major US life carriers that have agreed to issue life
      insurance on qualified residents of Israel at their very best US rates.
      This represents a tremendous opportunity for residents of Israel that have
      a need/desire for economically priced guaranteed financial liquidity at death.`,
      `The problem is that while it is permitted under Israel law for residents
      of Israel to purchase/own US life insurance, it is not permitted for
      non-Israel admitted (licensed) carriers to sell their products in Israel.
      Therefore, it is necessary for prospective purchasers to travel to the US
      (sometimes Switzerland) to actually purchase the policy which requires the
      completion and signing of an application and a medical exam.`,
    ],
  };
  return (
    <PageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="Why Buy US Life Insurance?"
    />
  );
};

export default Page;
